<template>
  <div>
    <div class="check-events" v-if="!ano">
      {{ $t('eventos.semEventos') }}
    </div>
    <Loading :active="active" />
    <div class="sticky top-20 bg-white z-20 px-5 pb-5">
      <h2 class="font-bold text-xl">{{ ano }}</h2>
      <div class="flex justify-between">
        <div>
          <a class="bg-white p-5" href="#" @click.prevent="getTempo(1)">
            <i class="fa fa-chevron-left"></i>
          </a>
        </div>
        <div>
          <h3 class="font-bold text-base">{{ mes | formatMonth }}</h3>
        </div>
        <div>
          <a class="bg-white p-5" href="#" @click.prevent="getTempo(-1)">
              <i class="fa fa-chevron-right"></i>
            </a>
        </div>
      </div>
    </div>
    <div class="events-content">
      <div class="events relative" v-for="(e, k) in eventsFilter" :key="k" @click.prevent="getPage(e.id, slugify(e.title))">
        <div class="font-bold text-xl">
          {{ e.dia }}{{ /* e.dia !== e.diaf ? '-'+ e.diaf : '' */ }}
        </div>
        <div class="font-bold text-sm absolute right-1 text-white px-1 top-1" :class="e.type">
          {{ e.inicio | formatWeek }}
        </div>
        <div>
          <div :class="`pokebola-${e.type}`" class="flex justify-center">
            <div class="img ml-12 rounded-full w-14 h-14 flex justify-center items-center mt-5" :class="e.type">
              <img class="igg" :src="getSrc(e)" style="margin-right: 5px" @error="getImage" />
            </div>
          </div>
          <div class="x absolute top-20 right-0" v-if="e.shiny"><img src="../assets/shiny.png"></div>
        </div>
        <div :class="e.type" class="mt-2 py-1 text-xs text-white">
            {{ e.typeDesc.title }}
          </div>
         <!-- <div class="event" :class="e.type">
            {{ e.typeDesc.title }}
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.css'
//import VueLoadImage from 'vue-load-image'
import Loading from '../components/Loading'

export default {
  props: ['params'],
  components: {
    //VueLoadImage,
    Loading
  },
  computed: {
    eventsFilter() {
      let elemento = this.events
        elemento = !this.params.length
          ? elemento
          : elemento.filter(i => this.params.includes(i.type))
        return elemento
    },
    checkEvents() {
      let events = this.eventsFilter
      return events.filter(elemento => elemento.event.length).length
    }
  },
  data: () => ({
    events: [],
    active: false,
    ano: '',
    mes: '',
    event: [],
    index: 0
  }),
  filters: {
    formatDate(x) {
      return moment(x).format('ddd, DD MMM - HH:mm')
    },
    formatMonth(x) {
      return moment()
        .set('month', x - 1)
        .format('MMMM')
    },
    formatWeek(x) {
      return moment(x).format('dddd').substring(0, 3)
    }
  },
  methods: {
    slugify(text) {
      return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    },
    async getMeses() {
      const list = await axios(`${this.$url}/eventos/n`, {
        params: {
          params: this.params.toString()
        }
      })
      this.event = list.data
    },
    getTempo(x) {
      this.index += x
      const z = this.event[this.index]
      if(z) {
      this.ano = z.ano
      this.mes = z.mes
      this.getDadosAno(z.ano, z.mes)
      } else {
        this.index -= x
      }
    },
    async getDadosAno(ano, mes) {
      this.active = true
      const list = await axios(`${this.$url}/eventos/new`, {
        params: {
          ano, mes
        }
      })
      this.events = list.data.eventos.map(i => {
        i.typeDesc = [
          {
            title: this.$t('locais.type.comunitario'),
            text: 'comunitario'
          },
          {
            title: this.$t('locais.type.raid'),
            text: 'raid'
          },
          {
            title: this.$t('locais.type.horaRaid'),
            text: 'horaRaid'
          },
          {
            title: this.$t('locais.type.horaDestaque'),
            text: 'horaDestaque'
          },
          {
            title: this.$t('locais.type.liga'),
            text: 'liga'
          },
          {
            title: this.$t('locais.type.geral'),
            text: 'geral'
          }
        ].filter(x => x.text === i.type)[0]
        return i
      })
      this.active = false
    },
    async getDados() {
      this.active = true
      const list = await axios(`${this.$url}/eventos/new`, {
        params: {
          params: this.params.toString()
        }
      })
      this.ano = list.data.ano
      this.mes = list.data.mes
      this.events = list.data.eventos.map(i => {
        i.typeDesc = [
          {
            title: this.$t('locais.type.comunitario'),
            text: 'comunitario'
          },
          {
            title: this.$t('locais.type.raid'),
            text: 'raid'
          },
          {
            title: this.$t('locais.type.horaRaid'),
            text: 'horaRaid'
          },
          {
            title: this.$t('locais.type.horaDestaque'),
            text: 'horaDestaque'
          },
          {
            title: this.$t('locais.type.liga'),
            text: 'liga'
          },
          {
            title: this.$t('locais.type.geral'),
            text: 'geral'
          }
        ].filter(x => x.text === i.type)[0]
        return i
      })
      /* this.events = list.data.map(x => {
        x.eventos.map(i => {
          i.typeImg = require(`../assets/eventos/${i.type}.png`)
          try {
            i.img = i.img
              ? `${this.$url}/storage/${i.img}`
              : require('../assets/banner_400.jpg')
          } catch (e) {
            i.img = require('../assets/banner_400.jpg')
          }
          if (this.$i18n.locale === 'es-es') {
            i.title = i.title_es
          } else if (this.$i18n.locale === 'en-us') {
            i.title = i.title_en
          }
          i.typeDesc = [
            {
              title: this.$t('locais.type.comunitario'),
              text: 'comunitario'
            },
            {
              title: this.$t('locais.type.raid'),
              text: 'raid'
            },
            {
              title: this.$t('locais.type.horaRaid'),
              text: 'horaRaid'
            },
            {
              title: this.$t('locais.type.horaDestaque'),
              text: 'horaDestaque'
            },
            {
              title: this.$t('locais.type.liga'),
              text: 'liga'
            },
            {
              title: this.$t('locais.type.geral'),
              text: 'geral'
            }
          ].filter(x => x.text === i.type)[0]
          return i
        })
        return x
      }) */
      this.active = false
    },
    getPage(id, title) {
      this.$router.push(
        `${this.$i18n.locale === 'pt-br'
          ? `/eventos/${id}/${title}`
          : this.$i18n.locale === 'es-es'
            ? `/eventos/${id}/${title}`
            : `/eventos/${id}/${title}`
        }`
      )
    },
    leftPad(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    getSrc(i) {
      let v
      if (i.pokemon) {
        if (i.pokemon.match(/.f/)) {
          v = i.pokemon
        } else {
        v = `${this.leftPad(i.pokemon, 3)}`
      }
      } else if (i.value5) {
        v = `${this.leftPad(i.pokemon, 3)}_${i.value5}`
      } else
        if (i.pokemon) {
          let nn
          if (i.pokemon.match(/Alola/)) {
            nn = '.fALOLA'
          } else if (i.pokemon.match(/Outono/)) {
            nn = '.fAUTUMN'
          } else if (i.pokemon.match(/Primavera/)) {
            nn = '.fSPRING'
          } else if (i.pokemon.match(/Verão/)) {
            nn = '.fSUMMER'
          } else if (i.pokemon.match(/Inverno/)) {
            nn = '.fWINTER'
          }
          else if (i.pokemon.match(/Galarian/)) {
            nn = '.fGALARIAN'
          } else {
            if (i.numero == 327) {
              nn = '.f00'
            } else if (i.numero == 412) {
              nn = '.fBURMY_PLANT'
            }
            else if (i.numero == 412) {
              nn = '.fBURMY_PLANT'
            }
            else {
              nn = ''
            }
          }
          v = i.numero + nn
        }
      const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/Addressable%20Assets/'
      if (!i.value4) {
        return `${url}pm${v}${i.shiny ? '' : ''}.icon.png`
      } else {
        return i.value4
      }
    },
    getImage(e) {
      e.target.src = require('../assets/pokemon/0.png')
    },
  },
  mounted() {
    moment.locale(this.$i18n.locale)
    Promise.all([this.getMeses(), this.getDados()])
  }
}
</script>

<style lang="scss" scoped>
.tempo {
  display: block;
  padding-bottom: 20px;
  position: sticky;
  top: 65px;
  background: #fff;
  z-index: 9;

  .steps {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }

  .steps strong {
    position: relative;
    bottom: -15px;
    display: inline-block;
    padding: 3px;
    background-color: #fff;
  }
}

.x {
  img {
    height: 25px;
  }
}

.card {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;

  .header {
    display: flex;

    .img {
      margin-right: 10px;


      img {
        height: 20px;
      }
    }

    .title {
      padding-top: 3px;
      color: #fff;
      font-weight: bold;
      font-size: 13px;
      position: relative;
    }
  }

  .content {
    display: flex;

    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      cursor: pointer;
      text-decoration: none;

      i {
        color: #fff;
      }
    }

    .img {
      img {
        height: 80px;
      }
    }

    .inicio {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      //background-color: #fff;
      margin-bottom: 4px;
      margin-left: 4px;
      flex: 1;

      .content-inicio {
        border-left: 3px solid #ccc;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        font-size: 12px;
        color: #fff;
      }

      .content-fim {
        border-left: 3px solid #ffc107;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        font-size: 12px;
        color: #fff;
      }

      .text {
        font-weight: bold;
      }
    }
  }

  .countdown {
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    padding: 5px;
    position: relative;
  }
}
.events-content {
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  gap: 3px;

  .events {
    display: flex;
    widows: 100%;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    margin: 2px;
    border: 2px solid;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid #ccc;
    .pokebola {}
    .img {
      img {
        height: 50px;
      }
    }
  }
}

.comunitario {
  background-color: #673bb7;
}

.raid {
  background-color: #0063d9;
}

.horaRaid {
  background-color: #007fe3;
}

.horaDestaque {
  background-color: #0094d6;
}

.liga {
  background-color: #00a5b9;
}

.geral {
  background-color: #00b296;
}





.pokebola-comunitario {
  background-image: url( '../assets/pokebola-comunitario.svg' );
  background-repeat: no-repeat;
  background-position: center;
}

.pokebola-raid {
  background-image: url( '../assets/pokebola-raid.svg' );
  background-repeat: no-repeat;
  background-position: center;
}

.pokebola-horaRaid {
  background-image: url( '../assets/pokebola-horaRaid.svg' );
  background-repeat: no-repeat;
  background-position: center;
}

.pokebola-horaDestaque {
  background-image: url( '../assets/pokebola-horaDestaque.svg' );
  background-repeat: no-repeat;
  background-position: center;
}

.pokebola-liga {
  background-image: url( '../assets/pokebola-liga.svg' );
  background-repeat: no-repeat;
  background-position: center;
}

.pokebola-geral {
  background-image: url( '../assets/pokebola-geral.svg' );
  background-repeat: no-repeat;
  background-position: center;
}

.event {
  content: '50%';
  position: absolute;
  bottom: 3px;
  right: 3px;
  text-align: center;
  padding: 1px 0;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  text-shadow: 0 0 3px rgb(0 0 0 / 50%);
  box-shadow: 0 0 3px hsl(0deg 0% 100% / 54%);
  width: 50px;
  font-size: 8px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-events {
  margin: 0 auto;
  width: 90%;
  font-size: 11px;
  color: #2c3e50;
  border: 1px solid #673bb7;
  background: #dbd1ec;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}

</style>
